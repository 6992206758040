import React, { useState } from 'react'
import {
  Box,
  createStyles,
  Grid,
  makeStyles,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import Button from '../Button/Button'
import Link from '../Link/Link'
import { useMatomo } from '@datapunt/matomo-tracker-react'
import CMSText, { prismicText } from '../Global/CMSText'

const rootAltGap = 167
const rootAltGapSmall = 80

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(8),
      textAlign: 'center',
    },
    comparator: {
      display: 'flex',
      justifyContent: 'center',
      margin: theme.spacing(3, 0),
    },
    fakeForm: {
      fontSize: theme.typography.pxToRem(20),
      lineHeight: 1.4,
      marginBottom: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        fontSize: theme.typography.pxToRem(38),
        lineHeight: 1,
        padding: theme.spacing(0, 0),
      },
      '& p': {
        display: 'inline-block',
        verticalAlign: 'middle',
        margin: 0,
      },
      '& .MuiInput-root': {
        position: 'relative',
        top: '.05em',
        display: 'inline-flex',
        margin: theme.spacing(0, 1),
        fontSize: theme.typography.pxToRem(20),
        height: '1.9em',
        '&:before, &:after': {
          display: 'none',
        },
        [theme.breakpoints.up('md')]: {
          fontSize: theme.typography.pxToRem(38),
        },
      },
      '& .MuiSelect-root': {
        paddingLeft: theme.spacing(1),
        borderRadius: 4,
        border: `1px dashed ${theme.extraColors.greyMedium}`,
        fontWeight: 700,
        color: theme.palette.common.black,
      },
      '& strong': {
        color: theme.palette.secondary.main,
      },
    },
    table: {
      boxShadow: theme.shadows[3],
      padding: theme.spacing(2),
      margin: theme.spacing(2, 0),
      background:
        'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 80.23%, #F5F4F4 100.68%), #FFFFFF',
      [theme.breakpoints.up('md')]: {
        fontSize: 38,
        maxWidth: 360,
        margin: theme.spacing(0, 'auto'),
      },
    },
    row__buy: {
      fontSize: 14,
      padding: theme.spacing(1, 0),
      borderBottom: `1px dashed ${theme.extraColors.greyMedium}`,
    },
    row__rent: {
      padding: theme.spacing(1, 0),
      alignItems: 'baseline',
      '& span': {
        fontWeight: 400,
      },
      '& strong': {
        fontSize: 36,
        color: theme.palette.primary.main,
      },
    },
    small_text: {
      textAlign: 'center',
    },
    fakeFormWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    featuredTitle: {
      marginBottom: theme.spacing(3),
    },
    featuredImg: {
      width: '100%',
      maxHeight: 145,
      objectFit: 'contain',
      marginTop: theme.spacing(3),
    },
    rootAlt: {
      contentVisibility: 'auto',
      background: theme.palette.common.white,
      margin: theme.spacing(6, 0),
      [theme.breakpoints.up('md')]: {
        display: 'flex',
        alignItems: 'center',
      },
      '& .fakeForm': {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        [theme.breakpoints.up('md')]: {
          padding: theme.spacing(10, 0),
        },
      },
      '& .table': {
        marginBottom: theme.spacing(3),
      },
    },
    titleAlt: {
      marginBottom: theme.spacing(2),
      color: theme.extraColors.greyText,
    },
    showcase: {
      padding: theme.spacing(3),
      background: theme.palette.primaryTransparent.light,
      [theme.breakpoints.up('md')]: {
        position: 'relative',
        zIndex: 1,
        boxShadow: theme.shadows[1],
        padding: theme.spacing(10, 3),
        marginBottom: theme.spacing(1),
        width: `calc(50% + ${rootAltGapSmall}px)`,
        marginLeft: `-${rootAltGapSmall}px`,
        '& .container': {
          float: 'left',
          marginLeft: rootAltGapSmall,
          marginTop: 54,
        },
      },
      [theme.breakpoints.up('lg')]: {
        width: `calc(50% + ${rootAltGap}px)`,
        marginLeft: `-${rootAltGap}px`,
        '& .container': {
          marginLeft: rootAltGap,
        },
      },
    },
    calculator: {
      padding: theme.spacing(5, 1),
      [theme.breakpoints.up('md')]: {
        position: 'relative',
        zIndex: 2,
        boxShadow: theme.shadows[1],
        background: theme.palette.common.white,
        width: `calc(50% + ${rootAltGapSmall}px)`,
        '& .container': {
          float: 'right',
          marginRight: rootAltGapSmall / 2,
        },
      },
      [theme.breakpoints.up('lg')]: {
        width: `calc(50% + ${rootAltGap}px)`,
        '& .container': {
          marginRight: rootAltGap / 2,
        },
      },
    },
  })
)

type TProps = {
  homePage: any
  t?: any
}

const SavingsSectionSubscription = ({ homePage, t }: TProps) => {
  const { trackEvent } = useMatomo()

  const classes = useStyles()
  const theme = useTheme()
  const isSubscription =
    process.env.STORE_TYPE === 'subscription' ||
    process.env.STORE_TYPE === 'classic_subscription'
  const CMSDATA = homePage?.data
  const isFeatured = CMSDATA?.featured_product
  const savingsRanges = CMSDATA?.saving_range
  const isSavingsRanges = savingsRanges && savingsRanges.length

  const [price, setPrice] = useState(
    isSavingsRanges ? prismicText(savingsRanges[0].budget) : '10'
  )
  const [buyingPrice, setBuyingPrice] = useState(
    isSavingsRanges ? prismicText(savingsRanges[0].buy) : '10'
  )
  const [rentingPrice, setRentingPrice] = useState(
    isSavingsRanges ? prismicText(savingsRanges[0].rent) : '20'
  )
  const [discount, setDiscount] = useState(
    isSavingsRanges ? prismicText(savingsRanges[0].discount) : '30'
  )

  const handleRangeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const rangeVal = event.target.value as string
    const index =
      savingsRanges &&
      savingsRanges.findIndex((item) => prismicText(item.budget) === rangeVal)

    setBuyingPrice(
      isSavingsRanges ? prismicText(savingsRanges[index].buy) : '20'
    )
    setRentingPrice(
      isSavingsRanges ? prismicText(savingsRanges[index].rent) : '30'
    )
    setDiscount(
      isSavingsRanges ? prismicText(savingsRanges[index].discount) : '40'
    )
    setPrice(rangeVal)
  }

  const FakeForm = (
    <div className={`fakeForm ${classes.fakeForm}`}>
      <span>
        <CMSText
          asText
          data={CMSDATA?.start_savings_text}
          defaultText={t('texts:homepage:start_savings_text')}
        />
        <Select value={price} onChange={handleRangeChange}>
          {isSavingsRanges
            ? savingsRanges.map((savingsRange) => (
                <MenuItem
                  key={prismicText(savingsRange.budget)}
                  value={prismicText(savingsRange.budget)}
                >
                  {prismicText(savingsRange.budget)}
                </MenuItem>
              ))
            : [10, 20, 30].map((index) => {
                return (
                  <MenuItem key={index} value={index}>
                    {index}
                  </MenuItem>
                )
              })}
        </Select>
        <CMSText
          asText
          data={CMSDATA?.end_savings_text}
          defaultText={t('texts:homepage:end_savings_text')}
        />
        {!isSubscription && (
          <Typography variant="inherit" color="secondary">
            <strong>&nbsp;{discount}</strong>
          </Typography>
        )}
      </span>
    </div>
  )

  const Ticket = (
    <>
      {isFeatured && (
        <Typography
          className={classes.featuredTitle}
          variant="h3"
          component="h2"
          align="center"
        >
          <CMSText
            asText
            data={CMSDATA?.featured_product_title}
            defaultText={t('texts:homepage:featured_product_title')}
          />
        </Typography>
      )}
      <div className={`table ${classes.table}`}>
        {isFeatured && (
          <>
            <Typography variant="h3" align="center">
              {prismicText(CMSDATA?.featured_product_name)}
            </Typography>
            <img
              src={CMSDATA?.featured_product_image.url}
              alt={
                CMSDATA?.featured_product_image.alt === null
                  ? '/images/placeholder.jpg'
                  : CMSDATA.featured_product_image.alt
              }
              className={classes.featuredImg}
            />
          </>
        )}
        <Box
          display="flex"
          justifyContent="space-between"
          className={classes.row__buy}
        >
          <Typography>
            <CMSText
              asText
              data={CMSDATA?.buy_savings_text}
              defaultText={t('texts:homepage:buy_savings_text')}
            />
          </Typography>
          <Typography>{buyingPrice + ' '}</Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          className={classes.row__rent}
        >
          <Typography variant={'h3'}>
            <b>
              <CMSText
                asText
                data={CMSDATA?.rent_savings_text}
                defaultText={t('texts:homepage:rent_savings_text')}
              />
            </b>
          </Typography>
          <Typography color={'primary'} variant={'h2'}>
            <span>
              <b>{rentingPrice}</b>
            </span>
          </Typography>
        </Box>
      </div>
    </>
  )

  const CTA = ({ isOutlined = false }) => (
    <Link href={CMSDATA?.link_destination ? '/packs' : '/shop'}>
      <a>
        <Button
          variant={!isOutlined ? 'contained' : 'outlined'}
          color={'primary'}
          component="span"
          data-testid={'to_shop'}
          onClick={() =>
            trackEvent({
              category: 'HomePage',
              href: CMSDATA?.link_destination ? '/packs' : '/shop',
              action: `Click on ${
                isFeatured && isOutlined
                  ? prismicText(
                      CMSDATA?.featured_product_link_label,
                      t('texts:homepage:featured_product_link_label')
                    )
                  : prismicText(
                      CMSDATA?.link_label_savings,
                      t('texts:homepage:link_label_savings')
                    )
              }`,
              name: `Savings to ${
                CMSDATA?.link_destination ? '/packs' : '/shop'
              }`,
            })
          }
          size={
            useMediaQuery(theme.breakpoints.down('md')) ? 'small' : 'medium'
          }
        >
          {isFeatured && isOutlined ? (
            <CMSText
              asText
              data={CMSDATA?.featured_product_link_label}
              defaultText={t('texts:homepage:featured_product_link_label')}
            />
          ) : (
            <CMSText
              asText
              data={CMSDATA?.link_label_savings}
              defaultText={t('texts:homepage:link_label_savings')}
            />
          )}
        </Button>
      </a>
    </Link>
  )

  return !isFeatured ? (
    <div className={`section-savings section__spacer ${classes.root}`}>
      <Typography variant={'h4'} component="h2" color={'primary'}>
        <CMSText
          asText
          data={CMSDATA?.section_title_savings}
          defaultText={t('texts:homepage:section_title_savings')}
        />
      </Typography>
      <div className={classes.comparator}>
        <Grid
          container
          justify="center"
          spacing={useMediaQuery(theme.breakpoints.up('md')) ? 6 : 0}
        >
          <Grid item md={4} xs={12} className={classes.fakeFormWrapper}>
            {FakeForm}
          </Grid>
          <Grid item md={3} xs={10}>
            {Ticket}
          </Grid>
        </Grid>
      </div>
      <CTA />
      <div className={classes.small_text}>
        <Typography variant={'body2'} color={'textSecondary'}>
          <CMSText
            asText
            data={CMSDATA?.saving_mentions}
            defaultText={t('texts:homepage:saving_mentions')}
          />
        </Typography>
      </div>
    </div>
  ) : (
    <div className={`section-savings section__spacer ${classes.rootAlt}`}>
      <div className={classes.calculator}>
        <div className="container">
          <Typography
            align="center"
            className={classes.titleAlt}
            variant={useMediaQuery(theme.breakpoints.up('md')) ? 'h3' : 'body1'}
            component="h2"
          >
            <CMSText
              asText
              data={CMSDATA?.section_title_savings}
              defaultText={t('texts:homepage:section_title_savings')}
            />
          </Typography>
          {FakeForm}
          <Box display="flex" justifyContent="center">
            <CTA />
          </Box>
        </div>
      </div>
      <div className={classes.showcase}>
        <div className="container">
          {Ticket}
          <Box display="flex" justifyContent="center">
            <CTA isOutlined />
          </Box>
        </div>
      </div>
    </div>
  )
}

export default SavingsSectionSubscription
